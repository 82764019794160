define("discourse/plugins/discourse-user-card-badges/discourse/controllers/preferences-card-badge", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/models/badge", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _ajax, _badge, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesCardBadgeController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    saving = false;
    saved = false;
    filteredList(model) {
      return model.filterBy("badge.image");
    }
    static #_2 = (() => dt7948.n(this.prototype, "filteredList", [(0, _decorators.default)("model")]))();
    selectableUserBadges(filteredList) {
      return [_object.default.create({
        badge: _badge.default.create({
          name: _I18n.default.t("badges.none")
        })
      }), ...filteredList.uniqBy("badge.name")];
    }
    static #_3 = (() => dt7948.n(this.prototype, "selectableUserBadges", [(0, _decorators.default)("filteredList")]))();
    savingStatus(saving) {
      return saving ? "saving" : "save";
    }
    static #_4 = (() => dt7948.n(this.prototype, "savingStatus", [(0, _decorators.default)("saving")]))();
    selectedUserBadge(selectedUserBadgeId) {
      return this.selectableUserBadges.findBy("id", parseInt(selectedUserBadgeId, 10));
    }
    static #_5 = (() => dt7948.n(this.prototype, "selectedUserBadge", [(0, _decorators.default)("selectedUserBadgeId")]))();
    save() {
      this.setProperties({
        saved: false,
        saving: true
      });
      (0, _ajax.ajax)(`${this.user.path}/preferences/card-badge`, {
        type: "PUT",
        data: {
          user_badge_id: this.selectedUserBadgeId
        }
      }).then(() => {
        this.setProperties({
          saved: true,
          saving: false,
          "user.card_image_badge": this.get("selectedUserBadge.badge.image")
        });
      }).catch(() => {
        this.set("saving", false);
        this.dialog.alert(_I18n.default.t("generic_error"));
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = PreferencesCardBadgeController;
});